* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

body {
  margin: 0;
  font-family: "futura-pt", sans-serif;
  font-weight: 300;
  font-style: normal;
  background-color: black;
}

/*font-family: "futura-pt", sans-serif;
font-weight: 300;
font-style: normal;
Futura PT Medium
font-family: "futura-pt", sans-serif;
font-weight: 500;
font-style: normal;
Futura PT Bold
font-family: "futura-pt-bold", sans-serif;
font-weight: 700;
font-style: normal;
*/
